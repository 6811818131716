import React from 'react';
import '../css/Project.css';
 

const ProjectStryk = () => {
  return (
    <div className='project'>
      <h2>Stryk</h2>
      <p>Stryk project</p>
    </div>
  );
};

export default ProjectStryk;