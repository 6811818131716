import React from 'react';
import '../css/Project.css';
 

const About = () => {
  return (
    <div className='project'>
      <h2>About</h2>
      <p>Welcome to the homepage!</p>
    </div>
  );
};

export default About;