import React from 'react';
import '../css/Buttons.css';
import { NavLink, useNavigate } from 'react-router-dom';

const ButtonLarge = ({ label, icon: Icon, onClick, to, style, className, setIsButtonHovered }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (to) {
      navigate(to);
    } else if (onClick) {
      onClick();
    }
  };

  return to ? (
    <NavLink
      to={to}
      className={({ isActive }) => `button-large ${className} ${isActive ? 'active' : ''}`}
      style={style}
      onMouseEnter={() => setIsButtonHovered(true)}
      onMouseLeave={() => setIsButtonHovered(false)}
    >
      {Icon && <Icon className="button-large-icon" />}
      {label}
    </NavLink>
  ) : (
    <button
      className={`button-large hover-button ${className}`}
      onClick={handleClick}
      style={style}
      onMouseEnter={() => setIsButtonHovered(true)}
      onMouseLeave={() => setIsButtonHovered(false)}
    >
      {Icon && <Icon className="button-large-icon" />}
      {label}
    </button>
  );
};

export default ButtonLarge;
