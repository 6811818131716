import React, { useState }  from 'react';
import '../css/Project.css';
import ButtonLarge from '../components/Button-large';
import { useNavigate } from 'react-router-dom';


const Project = ({ title, description, image, imageAlt, buttonLink, to }) => {


  const navigate = useNavigate();


  // State to manage hover
  const [isButtonHovered, setIsButtonHovered] = useState(false);



  return (


    <div className="project">
      {/* Project 1 */}
      <div className="project-menu">
        <img
          src={image}
          alt={imageAlt}
          className="project-cover"
          style={{
            border: `${isButtonHovered ? '2px solid #3C008A' : '2px solid #201E1D'}`,
          }}
        />
      <div 
        className="project-info"
        style={{
          border: `${isButtonHovered ? '2px solid #3C008A' : '2px solid #201E1D'}`,
        }}
      >
        <h3 
        style={{
          color: `${isButtonHovered ? '#3C008A' : '#201E1D'}`,
        }}
        >{title}</h3>
        <p>{description}</p>
        <ButtonLarge
          className="button-large"
          label="View project"
          setIsButtonHovered={setIsButtonHovered} // Passing setIsButtonHovered if ButtonLarge needs it
          to={to}
        />
      </div>
      </div>
    </div>
  );
};

export default Project;


